import React, { useState, useEffect } from 'react';
import { getIdToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { signOutUsingFirebase } from '../firebase-config';
import { useUser } from './UserContext';
import Modal from './Modal';
import '../App.css';

function UserProfile() {
  const { currentUser } = useUser();
  const [user_data, setUserData] = useState();
  const [showDownload, setShowDownload] = useState(false);

  useEffect(() => {
    // get current user's profile data from backend
    const fetchUserData = async () => {
      try {
        const idToken = await getIdToken(currentUser);
        const response = await fetch("/api_profile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        }
        else {
          throw new Error("User not found");
        }
      }
      catch (error) {
        console.log('Error: ', error);
        await signOutUsingFirebase();
        setUserData();
      }
    };

    fetchUserData();
  }, [currentUser]);

  const navigate = useNavigate();

  return (
        <div className="page-content">
          <h2>User Profile</h2>
          {user_data ? (
          <div>
            <p>Username: @{user_data.username}</p>
            <p>Email: {user_data.email}</p>
            <p>Name: {user_data.name || 'Anonymous'}</p>
            <hr />
            <h3>Your MindFeed Memento Journal</h3>
            <div>(This is a record of all your practice sessions so far. You can also download it as a PDF or e-book.)</div>
            <div>&nbsp;</div>
            <div>Coming soon...</div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={() => setShowDownload(true)}>Download Journal</button>
              <Modal show={showDownload} title="Download Journal" onClose={() => setShowDownload(false)}>
                    <div>Coming soon: You will be able to download a journal containing all your mementos.</div>
              </Modal>
              <button className="app-button" onClick={() => navigate('/')}>Home</button>
              <button className="app-button" onClick={() => navigate('/logout')}>Logout</button>
            </div>
          </div>
          ) : (
          <p>No user logged in</p>
          )}
        </div>
    );
}

export default UserProfile;
