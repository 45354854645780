import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function About() {
  const navigate = useNavigate();

  const handleHome = () => {
      navigate('/');
  };

  return (
    <div className="page-content">
      <h2>About MindFeed</h2>
      <h3>Embark on a Journey of Personal Growth Backed By Sound Science</h3>
      <ul className="items-list">
          <li>MindFeed introduces you to 4 impactful practices designed to enrich your life — 
            experience <b>Mindful Moments</b> any time of the day, energize with <b>Purposeful Mornings</b>, reflect during <b>Hopeful Evenings</b>, and, 
            over time, engineer a <b>Meaningful Life</b>.</li>
          <li>Discover a holistic path to personal fulfillment through our approach that blends ancient wisdom with scientific principles. 
            We integrate time-honored concepts such as Mindfulness, Stoic Reflection, and harmonious living with nature — including your own intrinsic nature — 
            with ideas derived from scientifically observed universal patterns known as the "6CED" Tendencies of the Universe.</li>
          <li>For more details on the underlying science, please refer to my book <a href="https://meaning.lifevisor.ai" target="_blank" rel="noreferrer">An Engineer's Search for Meaning</a>.</li>
          <li>Click on the practice you want to learn and MindFeed will guide you through it.</li>
          <li>Each practice session takes only a few minutes, and at the end MindFeed uses Generative AI to automatically generate a fun, sharable memento of your session.</li>
          <li>Users who have signed in can edit and save the memento into their account, display it on their profile, and download a journal that documents their progression.</li>
          <li>Note that MindFeed only gives you quick intros to the practices. Eventually, you will need to learn the practice in more depth from an expert.</li>
      </ul>
      <h3>Background</h3>
      <p>MindFeed is being developed by <a href="https://bhalerao.org" target="_blank" rel="noreferrer">Vin Bhalerao</a>, as a part of the <a href="https://lifevisor.ai" target="_blank" rel="noreferrer">LifeVisor.AI project</a>. 
        The long term vision of this project is "Smarter People, Wiser Lives".</p>
      <p>Feedback? Suggestions? Please use the form on <a href="https://meaning.lifevisor.ai/#contact" target="_blank" rel="noreferrer">this page</a>.</p>
      <p>Click on Home to start becoming more peaceful, productive and happy — the scientific way!</p>
    
      <div className="dialog-button-bar">
          <button className="app-button" onClick={handleHome}>Home</button>
      </div>
    </div>
  );
}

export default About;
