import { React, useEffect, useState }from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from './UserContext';
import FormatMemento from './FormatMemento';
import ShareMemento from './ShareMemento';
import Modal from './Modal';
import '../App.css';
import { writingFromMementoSubtype, practiceFromMementoType, adjectiveFromMementoSubtype, backColorFromMemento, fontColorFromMemento } from './Utils';

function Memento() {
    const { currentUser } = useUser();
    const [memento, setMemento] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showEdit, setShowEdit] = useState(false);

/*     const handleLike = () => {
        memento.likes++;
        setMemento(memento);
        // TODO: Update backend
    };
 */
  
    async function fetchData(id) {
        try {
            const response = await fetch('/api_memento/' + id, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error('Memento not found.');
            }
    
            const memento = await response.json();

            // if memento.top_img_name is null, it means the img is in db instead of as an img file on disk and should be accessed using the encoded memento.id
            if (!memento.top_img_name)
                memento.top_img_name = id;

            setMemento(memento);
            setLoading(false);
        }
        catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }
    
    const routeParams = useParams();

    useEffect(() => {
        fetchData(routeParams.id);
    }, [routeParams]);
    
    const navigate = useNavigate();

    const handleEdit = () => {
        setShowEdit(true);
    };

    const handleHome = () => {
        navigate('/');
    };
    
    const handleTopImgClick = () => {
        window.location.href = '/top_img/' + memento.top_img_name;
    };
        
    // check if this is the current user's own memento or someone else's
    const yourMemento = (currentUser && memento && (memento.user.auth_uid === currentUser.uid));

    return (
        <div className="page-content">
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <div>
                    <h2>A Memento to Commemorate {yourMemento ? "Your" : "a"} MindFeed Practice Session</h2>
                    <p>This {writingFromMementoSubtype(memento.subtype)} was inspired by a "{practiceFromMementoType(memento.type)}" practice session.</p>
                    {currentUser ? null : (<p><button class="link-button" onClick={() => navigate('/login')}>Signed in users</button> can edit the memento and save it to their profile.</p>)}
                    <div className="memento-container" style={{ backgroundColor: backColorFromMemento(memento), color: fontColorFromMemento(memento) }}>
                        <div className="memento-top-info">
                            <span className="username">@{(memento.user && memento.user.username) ? memento.user.username : 'Anonymous'}</span>
                            <span className="date">{new Date(memento.timestamp).toLocaleDateString()}</span>
                        </div>
                        <div className="memento">
                            <div>
                                <img className="memento-top-img" src={'/top_img/' + memento.top_img_name} onClick={handleTopImgClick} alt={memento.title} />
                            </div>
                            <p className="memento-title">{memento.title}</p>
                            <p className="memento-type">[A ({adjectiveFromMementoSubtype(memento.subtype)}) {practiceFromMementoType(memento.type)}]</p>
                            <div className="memento-content">
                                <FormatMemento mementoContent={memento.content} />
                            </div>
                        </div>
                        <div className="memento-actions-bar">
                            {/* <button className="app-button" onClick={handleLike}><img src="/like.svg" width="16" height="16" alt="Like" title="Like" /> Like ({memento.likes})</button> */}
                            <ShareMemento memento={memento} />
                        </div>
                    </div>
                    <div className="dialog-button-bar">
                        {yourMemento ? <button className="app-button" onClick={handleEdit}>Edit</button> : ""}
                        <Modal show={showEdit} title="Download Journal" onClose={() => setShowEdit(false)}>
                            <div>Coming soon: You will be able to edit your memento.</div>
                        </Modal>
                        <button className="app-button" onClick={handleHome}>Home</button>
                    </div>
                </div>
            )}
        </div>
      );
}
  
export default Memento;
